import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { menu } from '../menu/menu';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-blog-read',
  templateUrl: './blog-read.component.html',
  styleUrls: ['./blog-read.component.scss']
})
export class BlogReadComponent implements OnInit {
  category: string = '';
  menuNav: any = [];
  myForm: FormGroup;
  content: any = [];

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private apiService: ApiService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      console.log(params);
      if(!this.isEmpty(params)){
        this.category = params.category;
        this.loadPost(params.titulo);
      }else{
        this.category = '';
      }
      if(params.category === 'sou-clinica'){
        this.menuNav = menu.SOUCLINICA;
      }else if(params.category === 'sou-familiar'){
        this.menuNav = menu.SOUFAMILIAR;
      }else if(params.category === 'sou-ed-fisica'){
        this.menuNav = menu.SOUPROFISSIONAL;
      }else{
        this.menuNav = menu.INSTITUCIONAL;
      }
    });

    const fb = this.formBuilder;
    this.myForm = fb.group({
      comentario: [null, [Validators.required, Validators.minLength(3)]],
    })

  }

  isEmpty(obj: any){
    for(var key in obj){
      if(obj.hasOwnProperty(key)){
        return false
      }
      return true
    }
  }

  onSubmit(){
    console.log(this.myForm);
  }

  loadPost(slug: string){
    this.apiService.getPost(slug).subscribe((data: any) => {
      this.content = data.data;
    }, err => {

    })
  }

}
