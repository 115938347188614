import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  env: any = environment;
  @Input() theme;
  @Input() content: any = []; 

  constructor(activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {}

  ngOnChanges(){
    // console.log('DENTRO DO COMPONENTE 2: ', this.content);
  }

}
