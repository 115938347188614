import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() theme: string = '';
  @Input() menu: any;
  mobile: boolean = false;

  constructor() { }

  ngOnInit(): void {

    let isMobile = navigator.userAgent;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(isMobile)){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
    // console.log('THEME: ', this.theme);
    if(this.theme == undefined){
      this.theme = ''
    }
  }

}
