<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Pesquisa'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="content" id="sobre-o-modelo-exerciencia">
  <div class="container">
    <div class="row">

      <div id="protocolos" style="height: 300px;" class="mb-3 col-lg-12">
        {{ search.text_protocols }}
        <img [src]="search.image_protocols" alt="">
        {{ search.video_protocols }}
      </div>
      <div id="aplicativo" style="height: 300px;" class="mb-3 col-lg-12">
        {{ search.text_app }}
        <img [src]="search.image_app" alt="">
        {{ search.video_app }}
      </div>
      <div id="grupo-de-pesquisa" style="height: 300px;" class="mb-3 col-lg-12">
        {{ search.text_search_group }}
        <img [src]="search.image_search_group" alt="">
        {{ search.video_search_group }}
      </div>
      <div id="orientacoes-e-co-orientacoes" style="height: 300px;" class="mb-3 col-lg-12">
        {{ search.text_guidelines }}
        <img [src]="search.image_guidelines" alt="">
        {{ search.video_guidelines }}
      </div>
    </div>
  </div>
</div>

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
