<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Instrucional Clinica'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="content">
  <div class="container">
    <h2 class="text-center d-block title title-sou-clinica" [ngClass]="'title-' + category" style="width: 100%;">Instrucional Clinica</h2>
    <div class="row">
      <div class="col-lg-6">
        <iframe  src="https://www.youtube.com/embed/Wp7PMUONolc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="col-lg-6">
        <div>
          <p>A Supervisão INSTRUCIONAL CLÍNICA objetiva principalmente possibilitar que o profissional de Ed. Física componha a equipe terapêutica alinhado inter e transdisciplinarmente com objetivos do plano de intervenção e PEI. </p>
          <p>planejando atendimento de maneira a tornar descrito as funções de cada profissional. Dentre as ações realizadas é oferecido  pelo Modelo ExerCiencia:</p>
          <ul>
            <li>Planejamento do conjunto de exercícios por classe/turma/grupo;</li>
            <li>Análise documental;</li>
            <li>Planejamento de projetos padrões;</li>
            <li>Acompanhamento presencial;</li>
            <li>Reunião com equipe.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="content">
  <div class="container">
    <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Titulo para essa parte</h2>
    <div class="row">

      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 1</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 2</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 3</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 4</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
