import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() theme: string = '';
  @Input() menu: any;

  constructor() { }

  ngOnInit(): void {

    if(this.theme == undefined){
      this.theme = ''
    }

  }

}
