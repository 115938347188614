import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { menu } from '../menu/menu';


@Component({
  selector: 'app-aplicativo',
  templateUrl: './aplicativo.component.html',
  styleUrls: ['./aplicativo.component.scss']
})
export class AplicativoComponent implements OnInit {
  category: string = '';
  menuNav: any = [];
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {

      console.log(params);

      if(!this.isEmpty(params)){
        this.category = params.category;
      }else{
        this.category = '';
      }

      if(params.category === 'sou-clinica'){
        this.menuNav = menu.SOUCLINICA;
      }else if(params.category === 'sou-familiar'){
        this.menuNav = menu.SOUFAMILIAR;
      }else if(params.category === 'sou-ed-fisica'){
        this.menuNav = menu.SOUPROFISSIONAL;
      }else{
        this.menuNav = menu.INSTITUCIONAL;
      }
    });
  }

  isEmpty(obj: any){
    for(var key in obj){
      if(obj.hasOwnProperty(key)){
        return false
      }
      return true
    }
  }

}
