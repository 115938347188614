<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Supervisão'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="content" id="sobre-o-modelo-exerciencia">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <p>Reuniões acontecem periodicamente, com horários agendados (videoconferência) entre supervisor e profissional de Ed Fisica. </p>
        <p>Orientações por texto e áudios são apresentados semanalmente. Sugerimos que as aulas de Ed Fisica sejam realizadas de forma particularizada, não necessariamente individualizadas.</p>
        <p>Disponibilizamos nossos protocolos de avaliação e registro de desempenho e ensinamos os profissionais a elaborarem outros  formatos de registro alinhados com práticas baseadas em evidência analítico-comportamentais (ABA).</p>
        <p>Alguns dos serviços prestados na supervisão são:</p>
        <ul>
          <li>Auxílio da definição dos objetivos da intervenção;</li>
          <li>Reuniões com equipe terapêutica interdisciplinar (quando houver);</li>
          <li>Orientações sobre avaliações;</li>
          <li>Manejo de comportamentos inadequados;</li>
          <li>Procedimentos de ensino e  análise de desempenho.</li>
        </ul>
        <p>Quaisquer dúvidas do professor com relação ao manejo ou ao gerenciamento do ambiente de aula devem ser relatados ao supervisor imediatamente.</p>
      </div>
    </div>
  </div>
</div>

<div class="products">
  <div class="container">
    <div class="row">
        <h2 class="text-center d-block title" style="width: 100%;">Mais informações</h2>
        <div class="col-lg-4">
          <div class="card">
            <img class="card-img-top" src="https://via.placeholder.com/350x190" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">Formativa</h5>
              <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
              <a [routerLink]=" '/formativa'" class="btn saiba-mais-sou-clinica">Saiba mais</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <img class="card-img-top" src="https://via.placeholder.com/350x190" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">Instrucional Familiar</h5>
              <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
              <a [routerLink]=" '/instrucional-familiar'" class="btn saiba-mais-sou-familiar">Saiba mais</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <img class="card-img-top" src="https://via.placeholder.com/350x190" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">Instrucional Educação Fisíca</h5>
              <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
              <a [routerLink]=" '/instrucional-clinica'" class="btn saiba-mais-sou-ed-fisica">Saiba mais</a>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
