import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { menu } from '../menu/menu';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss']
})
export class ResearchComponent implements OnInit {
  category: string = '';
  menuNav: any = [];
  search: any = [];
  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {

      console.log(params);

      if(!this.isEmpty(params)){
        this.category = params.category;
      }else{
        this.category = '';
      }

      if(params.category === 'sou-clinica'){
        this.menuNav = menu.SOUCLINICA;
      }else if(params.category === 'sou-familiar'){
        this.menuNav = menu.SOUFAMILIAR;
      }else if(params.category === 'sou-ed-fisica'){
        this.menuNav = menu.SOUPROFISSIONAL;
      }else{
        this.menuNav = menu.INSTITUCIONAL;
      }
    });

    this.getSearch();
  }

  getSearch(){
    this.apiService.getSearch().subscribe((data: any) => {
      this.search = data.data;
    }, err => {
      this.search = [];
    })
  }

  isEmpty(obj: any){
    for(var key in obj){
      if(obj.hasOwnProperty(key)){
        return false
      }
      return true
    }
  }

}
