<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Supervisão Formativa'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="content">
  <div class="container">
    <h2 class="text-center d-block title title-sou-ed-fisica" [ngClass]="'title-' + category" style="width: 100%;">Supervisão Formativa</h2>
    <div class="row">
      <div class="col-lg-6 d-flex flex-column justify-content-between">
        <div>
          <p>A Supervisão Formativa tem caráter de ensino do profissional de Ed Fisica para atuar com excelência na especialidade da área e, quase sempre, é um serviço contratado pelo próprio profissional. </p>
          <p>Os profissionais que contratam a supervisão tem feito isso visando sua qualificação profissional (tal como uma especialização contínua) e, consequentemente, gerar excelência nos seus atendimentos.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <iframe  src="https://www.youtube.com/embed/Wp7PMUONolc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container">
    <h2 class="text-center d-block title title-sou-ed-fisica" [ngClass]="'title-' + category" style="width: 100%;">Titulo para essa parte</h2>
    <div class="row">

      <div class="col-lg-12">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 1</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 2</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 3</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 4</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
