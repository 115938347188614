import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InstitutionalComponent } from './views/institutional/institutional.component';
import { HomeComponent } from './views/home/home.component';
import { FormativaComponent } from './views/formativa/formativa.component';
import { ContactComponent } from './views/contact/contact.component';
import { BlogComponent } from './views/blog/blog.component';
import { BlogReadComponent } from './views/blog-read/blog-read.component';
import { AboutComponent } from './views/about/about.component';
import { InstitucionalFamilyComponent } from './views/institucional-family/institucional-family.component';
import { InstitucionalClinicComponent } from './views/institucional-clinic/institucional-clinic.component';
import { CursosComponent } from './views/cursos/cursos.component';
import { MiniCursosComponent } from './views/mini-cursos/mini-cursos.component';
import { WorkshopsComponent } from './views/workshops/workshops.component';
import { ResearchComponent } from './views/research/research.component';
import { AplicativoComponent } from './views/aplicativo/aplicativo.component';
import { Error404Component } from './views/error404/error404.component';
import { SupervisionComponent } from './views/supervision/supervision.component';
import { FormationComponent } from './views/formation/formation.component';
import { LoginComponent } from './views/login/login.component';

const routes: Routes = [
  {
    path: '',
    component: InstitutionalComponent
  },
  {
    path: 'institucional',
    component: AboutComponent
  },
  {
    path: 'contato',
    component: ContactComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'blog/:titulo',
    component: BlogReadComponent
  },
  {
    path: 'formativa',
    component: FormativaComponent
  },
  {
    path: 'cursos',
    component: CursosComponent
  },
  {
    path: 'mini-cursos',
    component: MiniCursosComponent
  },
  {
    path: 'workshops',
    component: WorkshopsComponent
  },
  {
    path: 'supervisao',
    component: SupervisionComponent
  },
  {
    path: 'instrucional-familiar',
    component: InstitucionalFamilyComponent
  },
  {
    path: 'instrucional-clinica',
    component: InstitucionalClinicComponent
  },
  {
    path: 'pesquisa',
    component: ResearchComponent
  },
  {
    path: 'aplicativo',
    component: AplicativoComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: ':category',
    component: HomeComponent
  },
  {
    path: ':category/institucional',
    component: AboutComponent
  },
  {
    path: ':category/supervisao',
    component: SupervisionComponent
  },
  {
    path: ':category/formacao',
    component: FormationComponent
  },
  {
    path: ':category/contato',
    component: ContactComponent
  },
  {
    path: ':category/blog',
    component: BlogComponent
  },
  {
    path: ':category/blog/:titulo',
    component: BlogReadComponent
  },
  {
    path: ':category/formativa',
    component: FormativaComponent
  },
  {
    path: ':category/cursos',
    component: CursosComponent
  },
  {
    path: ':category/mini-cursos',
    component: MiniCursosComponent
  },
  {
    path: ':category/workshops',
    component: WorkshopsComponent
  },
  {
    path: ':category/institucional-familiar',
    component: InstitucionalFamilyComponent
  },
  {
    path: ':category/institucional-clinica',
    component: InstitucionalClinicComponent
  },
  {
    path: ':category/pesquisa',
    component: ResearchComponent
  },
  {
    path: ':category/aplicativo',
    component: AplicativoComponent
  },
  {
    path: ':category/login',
    component: LoginComponent
  },
  {
    path: '**',
    component: Error404Component
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
