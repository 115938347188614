<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Contato'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>
  <div class="container mb-5 mt-5">
    <div class="row">
      <div class="col-lg-6 text-center mb-5">
        <img src="./assets/contato.jpg" alt="Contato" class="img-fluid">
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <h5 class="d-flex flex-column" [ngClass]="'title-' + category">
            Entre em Contato preenchendo o formulário abaixo:
            <small class="msg-error mt-1"><span class="text-danger">*</span> Todos os campos são de preenchimento obraigatório.</small>
          </h5>
        </div>
        <form [formGroup]="myForm" (submit)="onSubmit()">
          <div class="form-group">
            <input type="text" formControlName="nome" class="form-control" id="nome" placeholder="Digite seu nome">
            <div *ngIf="myForm.controls['nome'].invalid && (myForm.controls['nome'].dirty || myForm.controls['nome'].touched)">
                <div *ngIf="myForm.controls['nome'].errors.required">
                <div class="msg-error mt-2"><span class="text-danger">*</span> O campo nome é obrigatório.</div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <input type="email" formControlName="email" class="form-control" id="email" placeholder="Digite seu e-mail">

            <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)">
                <div *ngIf="myForm.controls['email'].errors.required">
                <div class="msg-error mt-2"><span class="text-danger">*</span> O campo E-mail está incorreto.</div>
              </div>
            </div>

          </div>
          <div class="form-group">
            <input type="tel" formControlName="telefone" class="form-control" id="telefone" placeholder="Digite seu telefone">
            <div *ngIf="myForm.controls['telefone'].invalid && (myForm.controls['telefone'].dirty || myForm.controls['telefone'].touched)">
                <div *ngIf="myForm.controls['telefone'].errors.required">
                <div class="msg-error mt-2"><span class="text-danger">*</span> Informe um telefone para contato.</div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <textarea class="form-control" formControlName="msg" placeholder="Escreva sua mensagem"></textarea>
            <div *ngIf="myForm.controls['msg'].invalid && (myForm.controls['msg'].dirty || myForm.controls['msg'].touched)">
                <div *ngIf="myForm.controls['msg'].errors.required">
                <div class="msg-error mt-2"><span class="text-danger">*</span> É necessário escrever uma mensagem.</div>
              </div>
            </div>
          </div>
          <button class="btn style" [ngClass]="'enviar-' + category" type="submit">Enviar contato!</button>
        </form>
        <div class="mt-3">
          <ul class="data-contact">
            <li><strong>Telefone/WhatsApp: </strong> <a href="https://wa.me/+5585992688560" target="_blank" [ngClass]="'enviar-' + category">(85) 9 9268-8560</a> / <a href="https://wa.me/+5516991793831" target="_blank" [ngClass]="'enviar-' + category">(16) 9 9179-3831</a></li>
            <li><strong>E-mail: </strong><a href="maito:comercial@modeloexerciencia.com.br" [ngClass]="'enviar-' + category">comercial@modeloexerciencia.com.br</a> / <a href="mailto:modeloexerciencia@gmail.com" [ngClass]="'enviar-' + category">modeloexerciencia@gmail.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>


<app-footer [theme]="category" [menu]="menuNav"></app-footer>
