import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { HomeComponent } from './views/home/home.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { CategoryComponent } from './components/category/category.component';
import { InstitutionalComponent } from './views/institutional/institutional.component';
import { FormativaComponent } from './views/formativa/formativa.component';
import { ContactComponent } from './views/contact/contact.component';
import { HeaderInsideComponent } from './components/header-inside/header-inside.component';
import { BlogComponent } from './views/blog/blog.component';
import { BlogReadComponent } from './views/blog-read/blog-read.component';
import { AboutComponent } from './views/about/about.component';
import { InstitucionalFamilyComponent } from './views/institucional-family/institucional-family.component';
import { InstitucionalClinicComponent } from './views/institucional-clinic/institucional-clinic.component';
import { CursosComponent } from './views/cursos/cursos.component';
import { MiniCursosComponent } from './views/mini-cursos/mini-cursos.component';
import { WorkshopsComponent } from './views/workshops/workshops.component';
import { ResearchComponent } from './views/research/research.component';
import { AplicativoComponent } from './views/aplicativo/aplicativo.component';
import { Error404Component } from './views/error404/error404.component';
import { SupervisionComponent } from './views/supervision/supervision.component';
import { FormationComponent } from './views/formation/formation.component';
import { LoginComponent } from './views/login/login.component';
import { ScrollToTopDirective } from './directives/scroll-to-top.directive';
import { ApiService } from './services/api.service';
import { ReversePipe } from './pipes/reverse.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CarouselComponent,
    HomeComponent,
    CategoryComponent,
    InstitutionalComponent,
    FormativaComponent,
    ContactComponent,
    HeaderInsideComponent,
    BlogComponent,
    BlogReadComponent,
    AboutComponent,
    InstitucionalFamilyComponent,
    InstitucionalClinicComponent,
    CursosComponent,
    MiniCursosComponent,
    WorkshopsComponent,
    ResearchComponent,
    AplicativoComponent,
    Error404Component,
    SupervisionComponent,
    FormationComponent,
    LoginComponent,
    ScrollToTopDirective,
    ReversePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AngularSvgIconModule.forRoot(),
    NgxPaginationModule
  ],
  providers: [
    ApiService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { 
  constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far);
	}
}
