<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="content.title_article" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="container mb-5 mt-5">
  <div class="row">
    <h3 class="mb-3" style="width: 100%;">{{ content.title_article }}</h3>
    <!-- <img src="./assets/banner.png" class="img-fluid  mb-3" alt="Titulo do Post" > -->
    <div [innerHtml]="content.text_article"></div>

    <form [formGroup]="myForm" (submit)="onSubmit()" style="width: 100%;">
      <h3 class="mb-2 mt-3" style="width: 100%;">Deixe seu comentário</h3>
      <div class="form-group">
        <textarea class="form-control" formControlName="comentario" placeholder="Escreva seu comentário" style="width: 100%; height: 100px;"></textarea>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn style" [ngClass]="'enviar-' + category" type="submit" [disabled]="!myForm.valid">Enviar comentário!</button>
      </div>
    </form>
  </div>
</div>

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
