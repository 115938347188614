<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Instrucional Familiar'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="content">
  <div class="container">
    <div class="row">

      <div class="content">
        <div class="container">
          <h2 class="text-center d-block title title-sou-familiar" [ngClass]="'title-' + category" style="width: 100%;">Instrucional Familiar</h2>
          <div class="row">
            <div class="col-lg-6">
              <iframe  src="https://www.youtube.com/embed/Wp7PMUONolc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-lg-6">
              <p>A Supervisão INSTRUCIONAL tem como objetivo garantir o máximo possível de qualidade no atendimento e não tem o propósito formativo do professor.</p>
              <p>Comumente é um serviço contratado por uma clínica/instituição de atendimento OU pela família do(a) aprendiz com autismo/desenvolvimento atípico. Orientamos “o que deve ser feito”, proporcionando ao profissional um melhor desempenho em seu atendimento. </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 1</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 2</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 3</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bloco 4</h5>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</div>

<!-- <div class="content">
  <div class="container">
    <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Institucional Familiar</h2>
    <div class="row">
      <div class="col-lg-6">
        <iframe  src="https://www.youtube.com/embed/Wp7PMUONolc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="col-lg-6 d-flex flex-column justify-content-between">
        <div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ornare, elit vel tincidunt porttitor, turpis tortor lobortis turpis, at rutrum felis dolor quis leo. </p>
          <p>Nunc pharetra tincidunt elit non bibendum. Praesent iaculis augue a tortor pharetra. </p>
          <p>Sed congue nunc orci, vel suscipit mauris fringilla placerat. Etiam sed sapien auctor, convallis dolor ac, finibus justo. Quisque ac rutrum leo</p>
        </div>
      </div>
    </div>
  </div>
</div> -->

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
