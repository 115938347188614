export const menu = {
  INSTITUCIONAL: [
    {
      name: 'Home', path: '',
      submenu: []
    },
    {
      name: 'Institucional', path: 'institucional',
      submenu: [
        {name: 'Sobre o Modelo Exerciência', hash: 'sobre-o-modelo-exerciencia', path: 'institucional'},
        {name: 'Nossa História', hash: 'nossa-historia', path: 'institucional'},
        {name: 'Quem Somos', hash: 'quem-somos', path: 'institucional'},
      ]
    },
    {
      name: 'Supervisão', path: 'supervisao',
      submenu: [
        {name: 'Formativa', path: 'formativa'},
        {name: 'Instrucional Familiar', path: 'instrucional-familiar'},
        {name: 'Instrucional Clínica', path: 'instrucional-clinica'},
      ]
    },
    {
      name: 'Formação', path: 'formacao',
      submenu: [
        {name: 'Cursos', path: 'cursos'},
        {name: 'Mini cursos', path: 'mini-cursos'},
        {name: 'Workshops', path: 'workshops'},
      ]
    },
    {
      name: 'Pesquisa', path: 'pesquisa',
      submenu: [
        {name: 'Protocolos', hash: 'protocolos', path: 'pesquisa'},
        {name: 'APP', hash: 'aplicativo', path: 'pesquisa'},
        {name: 'Grupo de Pesquisa', hash: 'grupo-de-pesquisa', path: 'pesquisa'},
        {name: 'Orientações e Co-orientações', hash: 'orientacoes-e-co-orientacoes', path: 'pesquisa'},
      ]
    },
    {
      name: 'Blog', path: 'blog',
      submenu: []
    },
    {
      name: 'Contato', path: 'contato',
      submenu: []
    },
    {
      name: 'Login', path: 'login',
      submenu: []
    },
  ],
  SOUCLINICA: [
    {
      name: 'Home', path: '',
      submenu: []
    },
    {
      name: 'Institucional', path: 'institucional',
      submenu: [
        {name: 'Sobre o Modelo Exerciência', hash: 'sobre-o-modelo-exerciencia', path: 'institucional'},
        {name: 'Nossa História', hash: 'nossa-historia', path: 'institucional'},
        {name: 'Quem Somos', hash: 'quem-somos', path: 'institucional'},
      ]
    },
    {
      name: 'Supervisão', path: 'supervisao',
      submenu: []
    },
    {
      name: 'Formação', path: 'formacao',
      submenu: []
    },
    {
      name: 'Blog', path: 'blog',
      submenu: []
    },
    {
      name: 'Contato', path: 'contato',
      submenu: []
    },
    {
      name: 'Login', path: 'login',
      submenu: []
    }
  ],
  SOUFAMILIAR: [
    {
      name: 'Home', path: '',
      submenu: []
    },
    {
      name: 'Institucional', path: 'institucional',
      submenu: [
        {name: 'Sobre o Modelo Exerciência', hash: 'sobre-o-modelo-exerciencia', path: 'institucional'},
        {name: 'Nossa História', hash: 'nossa-historia', path: 'institucional'},
        {name: 'Quem Somos', hash: 'quem-somos', path: 'institucional'},
      ]
    },
    {
      name: 'Supervisão', path: 'supervisao',
      submenu: []
    },
    {
      name: 'Pesquisa', path: 'pesquisa',
      submenu: [
        {name: 'Protocolos', hash: 'protocolos', path: 'pesquisa'},
        {name: 'APP', hash: 'aplicativo', path: 'pesquisa'},
        {name: 'Grupo de Pesquisa', hash: 'grupo-de-pesquisa', path: 'pesquisa'},
        {name: 'Orientações e Co-orientações', hash: 'orientacoes-e-co-orientacoes', path: 'pesquisa'},
      ]
    },
    {
      name: 'Blog', path: 'blog',
      submenu: []
    },
    {
      name: 'Contato', path: 'contato',
      submenu: []
    },
    {
      name: 'Login', path: 'login',
      submenu: []
    },
  ],
  SOUPROFISSIONAL: [
    {
      name: 'Home', path: '',
      submenu: []
    },
    {
      name: 'Institucional', path: 'institucional',
      submenu: [
        {name: 'Sobre o Modelo Exerciência', hash: 'sobre-o-modelo-exerciencia', path: 'institucional'},
        {name: 'Nossa História', hash: 'nossa-historia', path: 'institucional'},
        {name: 'Quem Somos', hash: 'quem-somos', path: 'institucional'},
      ]
    },
    {
      name: 'Supervisão', path: 'supervisao',
      submenu: []
    },
    {
      name: 'Formação', path: 'formacao',
      submenu: []
    },
    {
      name: 'Pesquisa', path: 'pesquisa',
      submenu: []
    },
    {
      name: 'Blog', path: 'blog',
      submenu: []
    },
    {
      name: 'Contato', path: 'contato',
      submenu: []
    },
    {
      name: 'Login', path: 'login',
      submenu: []
    },

  ]
}
