<div class="category" [ngClass]="theme">
  <div class="container">
    <div class="row">

      <!-- Menu DESKTOP -->
      <div class="col-lg-12 d-flex align-items-center category-nav">
        <div class="home">
          <a [routerLink]=" '/' " class="d-flex justify-content-center">
            <svg-icon src="./assets/home.svg" [svgStyle]="{ 'width.px':36 }"></svg-icon>
          </a>
        </div>
        <div class="categories d-flex justify-content-center flex-grow-1">
          <a [routerLink]=" '/sou-clinica' " [routerLinkActive]="'active'" class="link clinic d-flex justify-content-center align-items-center">
            <span class="mr-2">
              <svg-icon src="./assets/clinics.svg" [svgStyle]="{ 'width.px':26 }"></svg-icon>
            </span>
            Sou Clinica
          </a>
          <a [routerLink]=" '/sou-familiar' " [routerLinkActive]="'active'"  class="link family d-flex justify-content-center align-items-center">
            <span class="mr-2">
              <svg-icon src="./assets/family.svg" [svgStyle]="{ 'width.px':35 }"></svg-icon>
            </span>
            Sou Familiar
          </a>
          <a [routerLink]=" '/sou-ed-fisica' " [routerLinkActive]="'active'"  class="link profissional d-flex justify-content-center align-items-center">
            <span class="mr-2">
              <svg-icon src="./assets/pro.svg" [svgStyle]="{ 'width.px':35 }"></svg-icon>
            </span>
            Sou Educação Fisíca
          </a>
        </div>
      </div>
      
      <!-- Menu MOBILE -->
      <div class="col-lg-12 d-flex category-nav-mobile">

          <a [routerLink]=" '/' " class="link d-flex justify-content-center align-items-center">
            <span class="mr-2">
              <svg-icon src="./assets/home.svg" [svgStyle]="{ 'width.px':36 }"></svg-icon>
            </span>
            Home
          </a>

          <a [routerLink]=" '/sou-clinica' " [routerLinkActive]="'active'" class="link clinic d-flex justify-content-center align-items-center">
            <span class="mr-2">
              <svg-icon src="./assets/clinics.svg" [svgStyle]="{ 'width.px':24 }"></svg-icon>
            </span>
            Sou Clinica
          </a>

          <a [routerLink]=" '/sou-familiar' " [routerLinkActive]="'active'"  class="link family d-flex justify-content-center align-items-center">
            <span class="mr-2">
              <svg-icon src="./assets/family.svg" [svgStyle]="{ 'width.px':34 }"></svg-icon>
            </span>
            Sou Familiar
          </a>

          <a [routerLink]=" '/sou-ed-fisica' " [routerLinkActive]="'active'"  class="link profissional d-flex justify-content-center align-items-center">
            <span class="mr-2">
              <svg-icon src="./assets/pro.svg" [svgStyle]="{ 'width.px':30 }"></svg-icon>
            </span>
            Sou Educação Fisíca
          </a>
          
      </div>

    </div>
  </div>
</div>
