<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-carousel [theme]="category"></app-carousel>
<app-category [theme]="category"></app-category>

<!--
<div class="institutional">
  <div class="container">
    <div class="row justify-content-center mt-5 mb-5">

      <div class="col-lg-3 text-center">
        <a [routerLink]=" '/sou-clinica' " class="picture mb-3">
          <span class="sou-clinica" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></span>
          <h6 class="mt-2">Sou Clinica</h6>
        </a>
      </div>

      <div class="col-lg-3 text-center">
        <a [routerLink]=" '/sou-familiar' " class="picture mb-3">
          <span class="sou-familiar" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></span>
          <h6 class="mt-2">Sou Familiar</h6>
        </a>
      </div>

      <div class="col-lg-3 text-center">
        <a [routerLink]=" '/sou-ed-fisica' " class="picture mb-3">
          <span class="sou-ed-fisica" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></span>
          <h6 class="mt-2">Sou Educação Fisíca</h6>
        </a>
      </div>

    </div>
  </div>
</div> -->

<div class="about">
  <div class="container">
    <div class="row">
      <h2 class="text-center d-block title" style="width: 100%;">Ciência, propósito e resultados</h2>
      <div class="col-lg-6">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ornare, elit vel tincidunt porttitor, turpis tortor lobortis turpis, at rutrum felis dolor quis leo. </p>
        <p>Nunc pharetra tincidunt elit non bibendum. Praesent iaculis augue a tortor pharetra. </p>
        <p>Sed congue nunc orci, vel suscipit mauris fringilla placerat. Etiam sed sapien auctor, convallis dolor ac, finibus justo. Quisque ac rutrum leo</p>
        <div class="btn-more d-flex justify-content-end">
          <a href="#" class="d-flex pl-2">
            Saiba Mais
            <span class="ml-2">
              <svg-icon src="./assets/arrow-right.svg" [svgStyle]="{ 'width.px':13 }"></svg-icon>
            </span>
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <iframe  src="https://www.youtube.com/embed/Wp7PMUONolc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div class="products">
  <div class="container">
    <div class="row">
        <h2 class="text-center d-block title" style="width: 100%;">Mais informações</h2>
        
        <div class="col-lg-4" *ngFor="let lc of listCategory; let idx = index;">
          <div class="card">
            <img *ngIf="lc.banner_category != '' " class="card-img-top" [src]="env.baseUpload + lc.banner_category" alt="Card image cap">
            <div class="card-body">
              <div>
                <h5 class="card-title">{{ lc.name_category }}</h5>
                <p class="card-text">{{ lc.resume_category }}</p>
              </div>
              <a [routerLink]="lc.slug_category" class="btn saiba-mais-{{ lc.slug_category }}">Saiba mais</a>
            </div>
          </div>
        </div>

    </div>
  </div>
</div>

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
