<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <h4 class="mb-3">Mapa do site</h4>
        <ul class="list">
            <li *ngFor="let item of menu; let i = index" class="mb-2">
              <a [routerLink]="'/' + theme + '/' + item.path">
                {{ item.name }}
              </a>
            </li>
        </ul>
      </div>
      <div class="col-lg-4 d-flex flex-column align-items-center">
        <h4 class="mb-3">Siga-nos</h4>
        <div class="d-flex">
          <a href="https://www.facebook.com/modeloexerciencia/" target="_blank" class="mr-2">
            <img src="./assets/face.png">
          </a>
          <a href="https://www.youtube.com/channel/UCnPe2oD5rjjoghhXJb2Pzug" target="_blank" class="mr-2">
            <img src="./assets/youtube.png">
          </a>
          <a href="https://www.instagram.com/modeloexerciencia/" target="_blank">
            <img src="./assets/instagram.png">
          </a>
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-column">
        <h4 class="mb-3">Onde estamos</h4>
        <a href="#">
          <img src="./assets/logo-negative.png" class="img-fluid mb-3">
        </a>
        <div>CNPJ: 31.169.321/0001-48</div>
        <!-- <div>Endereço da Empresa, Ribeirão Preto - SP</div> -->
        <div>WhatsApp: <a href="https://wa.me/+5585992688560" target="_blank">(85) 9 9268-8560</a> / <a href="https://wa.me/+5516991793831" target="_blank">(16) 9 9179-3831</a></div>
        <div>E-mail: <a href="maito:comercial@modeloexerciencia.com.br">comercial@modeloexerciencia.com.br</a> / <a href="mailto:modeloexerciencia@gmail.com">modeloexerciencia@gmail.com</a>
      </div>
    </div>
    <div class="col-lg-12 text-right mt-5">
        <a href="#" target="_blank">
          <img src="./assets/assinatura.png" alt="Made For Web" style="width: 90px;">
        </a>
      </div>
  </div>
</div>
