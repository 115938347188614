<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Blog'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="container mb-5 mt-5">
  <div class="row">
    <div *ngFor="let p of posts | reverse | paginate: { itemsPerPage: totalPage, currentPage: page };" class="mb-4 pb-4 d-flex division col-lg-12">
      <div class="col-lg-3">
        <a [routerLink]="p.slug_article">
          <img [src]="env.baseUpload + p.cover_article " alt="Titulo de Post" class="img-fluid">
        </a>
      </div>
      <div class="col-lg-9">
        <a [routerLink]="p.slug_article" class="text">
          <h3 class="mb-1" [ngClass]="'titulo-'+category">{{ p.title_article }}</h3>
          <span class="data mb-3">{{ p.created_at | date:'dd/mm/yyyy' }}</span>
          <div [innerHtml]="p.resume_article"></div>
        </a>
        <div class="cta d-flex justify-content-end">
          <a [routerLink]="p.slug_article" class="btn style" [ngClass]="'blog-' + category">Continuar lendo</a>
        </div>
      </div>
    </div>
    <div class="alert alert-danger col-lg-12" *ngIf="alert">
      Não encontramos conteúdo para o blog.
    </div>
   
    <div class="alert alert-dark col-lg-12" *ngIf="loadingPost">
      Procurando conteúdo, por favor aguarde. <fa-icon [icon]="['fas','spinner']" [spin]="true" class="fa-spin" size="1x"></fa-icon>
    </div>
    <div class="text-center col-lg-12">
      <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" nextLabel="Próximo"></pagination-controls>
    </div>
    
  </div>
</div>


<app-footer [theme]="category" [menu]="menuNav"></app-footer>
