import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { menu } from '../menu/menu';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  category: string = '';
  menuNav: any = [];
  myForm: FormGroup;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if(!this.isEmpty(params)){
        this.category = params.category;
      }else{
        this.category = '';
      }
      if(params.category === 'sou-clinica'){
        this.menuNav = menu.SOUCLINICA;
      }else if(params.category === 'sou-familiar'){
        this.menuNav = menu.SOUFAMILIAR;
      }else if(params.category === 'sou-ed-fisica'){
        this.menuNav = menu.SOUPROFISSIONAL;
      }else{
        this.menuNav = menu.INSTITUCIONAL;
      }
    });

    const fb = this.formBuilder;
    this.myForm = fb.group({
      nome: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      telefone: [null, [Validators.required]],
      msg: [null, [Validators.required, Validators.minLength(3)]]
    })
  }

  onSubmit(){
    console.log(this.myForm);
  }

  isEmpty(obj: any){
    for(var key in obj){
      if(obj.hasOwnProperty(key)){
        return false
      }
      return true
    }
  }

}
