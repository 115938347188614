<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Mini Cursos'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="content" id="sobre-o-modelo-exerciencia">
  <div class="container">
    <div class="row">
      Mini Cursos
    </div>
  </div>
</div>

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
