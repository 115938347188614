import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { menu } from '../menu/menu';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  category: string = '';
  menuNav: any = [];
  posts: any = [];
  env: any = environment;
  loadingPost: boolean = false;
  alert: boolean = false;
  page: number = 1;
  totalPage: number = 10;
  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {

      if(!this.isEmpty(params)){
        this.category = params.category;
        let catArr = [
          {id: 1, category: 'sou-clinica'},
          {id: 2, category: 'sou-familiar'},
          {id: 3, category: 'sou-ed-fisica'}
        ];

        catArr.forEach(el => {
          if(el.category === params.category){
            // console.log('ENTROU NO IF E PROCUROU BANNER');
            this.getPosts(el.id);
            return false;
          }
        });
      }else{
        this.category = '';
      }

      if(params.category === 'sou-clinica'){
        this.menuNav = menu.SOUCLINICA;
      }else if(params.category === 'sou-familiar'){
        this.menuNav = menu.SOUFAMILIAR;
      }else if(params.category === 'sou-ed-fisica'){
        this.menuNav = menu.SOUPROFISSIONAL;
      }else{
        this.menuNav = menu.INSTITUCIONAL;
      }
    });
  }

  isEmpty(obj: any){
    for(var key in obj){
      if(obj.hasOwnProperty(key)){
        return false
      }
      return true
    }
  }

  getPosts(id: number){
    this.loadingPost = true;
    this.apiService.getAllPosts(id).subscribe((data: any) => {
      console.log('BANNER: ', data.data.data);
      if(data.data.data.length > 0){
        this.posts = data.data.data;
        // this.totalPage = data.data.per_page;
        this.alert = false;
      }else{
        this.posts = [];
        this.alert = true;
      }
      this.loadingPost = false;
    }, err => {
      console.log('ERR: ', err);
    })
  }

}
