import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { menu } from '../menu/menu';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  category: string = '';
  menuNav: any = [];
  banners: any = [];
  posts: any = [];
  testimony: any = [];
  loading: boolean = false;
  env: any = environment;
  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) {

  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if(!this.isEmpty(params)){
        // console.log('params: ', params);
        this.category = params.category;
        let catArr = [
          {id: 1, category: 'sou-clinica'},
          {id: 2, category: 'sou-familiar'},
          {id: 3, category: 'sou-ed-fisica'}
        ];

        catArr.forEach(el => {
          if(el.category === params.category){
            // console.log('ENTROU NO IF E PROCUROU BANNER');
            this.getBanner(el.id);
            this.getPosts(el.id);
            this.getTestimony(el.id);
            return false;
          }
        });

      }else{
        this.category = '';
      }

      // console.log('--->>>>>', params, !this.isEmpty(params))

      if(params.category === 'sou-clinica'){
        this.menuNav = menu.SOUCLINICA;
      }else if(params.category === 'sou-familiar'){
        this.menuNav = menu.SOUFAMILIAR;
      }else if(params.category === 'sou-ed-fisica'){
        this.menuNav = menu.SOUPROFISSIONAL;
      }else{
        this.menuNav = menu.INSTITUCIONAL;
      }
    });

  }

  getBanner(id: number){
    this.loading = true;
    document.body.style.overflow = 'hidden';
    this.apiService.getBanner(id).subscribe((data: any) => {
      // console.log('BANNER: ', data.data);
      if(data.data.length > 0){
        this.banners = data.data;
      }else{
        this.banners = [];
      }
      this.loading = false;
      document.body.style.overflow = 'scroll';
    }, err => {
      console.log('ERR: ', err);
      this.loading = false;
    })
  }

  getPosts(id: number){
    this.apiService.getHomePosts(id).subscribe((data: any) => {
      // console.log('BANNER: ', data.data);
      if(data.data.length > 0){
        this.posts = data.data;
      }else{
        this.posts = [];
      }
    }, err => {
      console.log('ERR: ', err);
    })
  }

  getTestimony(id: number){
    this.apiService.getTestimony(id).subscribe((data: any) => {
      // console.log('BANNER: ', data.data);
      if(data.data.length > 0){
        this.testimony = data.data;
      }else{
        this.testimony = [];
      }
    }, err => {
      console.log('ERR: ', err);
    })
  }

  isEmpty(obj: any){
    for(var key in obj){
      if(obj.hasOwnProperty(key)){
        return false
      }
      return true
    }
  }

}
