import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-inside',
  templateUrl: './header-inside.component.html',
  styleUrls: ['./header-inside.component.scss']
})
export class HeaderInsideComponent implements OnInit {
  @Input() area: string;
  @Input() pathImage: string;
  @Input() theme: string;
  constructor() { }

  ngOnInit(): void {
  }

}
