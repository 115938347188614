<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-header-inside [area]="'Institucional'" [pathImage]="'../../../assets/banner-about.jpg'" [theme]="category"></app-header-inside>

<div class="content" id="sobre-o-modelo-exerciencia">
  <div class="container">
    <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Sobre o Modelo Exerciência</h2>
    <div class="row">
      <div class="col-lg-6">
        <iframe [src]="videoURL(about.video_about)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="col-lg-6 d-flex flex-column justify-content-between">
        <div [innerHTML]="about.text_about"></div>
      </div>
    </div>
  </div>
</div>


<div class="content" id="nossa-historia">
  <div class="container">
    <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Nossa História</h2>
    <div class="row">
      <div class="col-lg-6 d-flex flex-column justify-content-between">
        <div [innerHTML]="about.text_story"></div>
      </div>
      <div class="col-lg-6">
        
        <!-- https://www.youtube.com/embed/Wp7PMUONolc -->
        <iframe [src]="videoURL(about.video_story)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div class="content" id="quem-somos">
  <div class="container">
    <div class="row">

      <div class="col-lg-12">

        <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Quem Somos</h2>
        <div [innerHTML]="about.text_we_are"></div>
        <div class="row mt-5">
          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Missão</h5>
                <div [innerHTML]="about.mission"></div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Visão</h5>
                <div [innerHTML]="about.vision"></div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Valores</h5>
                <div [innerHTML]="about.values"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-footer [theme]="category" [menu]="menuNav"></app-footer>
