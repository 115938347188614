<div class="loading" *ngIf="loading">
  <fa-icon [icon]="['fas','spinner']" [spin]="true" class="fa-spin" size="3x"></fa-icon>
</div>

<app-header [theme]="category" [menu]="menuNav"></app-header>
<app-carousel [theme]="category" [content]="banners"></app-carousel>
<app-category [theme]="category"></app-category>

<div class="body">

  <div class="about">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-between">
          <div>
            <h2 [ngClass]="'title-' + category">Quem Somos</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ornare, elit vel tincidunt porttitor, turpis tortor lobortis turpis, at rutrum felis dolor quis leo. </p>
            <p>Nunc pharetra tincidunt elit non bibendum. Praesent iaculis augue a tortor pharetra. </p>
            <p>Sed congue nunc orci, vel suscipit mauris fringilla placerat. Etiam sed sapien auctor, convallis dolor ac, finibus justo. Quisque ac rutrum leo</p>
          </div>
          <div class="btn-more d-flex justify-content-end">
            <a href="#" class="d-flex pl-2" [ngClass]="'saiba-mais-' + category">
              Saiba Mais
              <span class="ml-2">
                <svg-icon src="./assets/arrow-right.svg" [svgStyle]="{ 'width.px':13 }"></svg-icon>
              </span>
            </a>
          </div>
        </div>
        <div class="col-lg-6">
          <iframe  src="https://www.youtube.com/embed/Wp7PMUONolc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="supervision" *ngIf="category === 'sou-clinica' || category === 'sou-ed-fisica' || category === 'sou-familiar' ">
    <div class="container">
      <div class="row">
        <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Supervisão</h2>
        <div class="col-lg-6">
          <iframe  src="https://www.youtube.com/embed/Wp7PMUONolc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-lg-6 d-flex flex-column justify-content-between">
          <div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ornare, elit vel tincidunt porttitor, turpis tortor lobortis turpis, at rutrum felis dolor quis leo. </p>
            <p>Nunc pharetra tincidunt elit non bibendum. Praesent iaculis augue a tortor pharetra. </p>
            <p>Sed congue nunc orci, vel suscipit mauris fringilla placerat. Etiam sed sapien auctor, convallis dolor ac, finibus justo. Quisque ac rutrum leo</p>
          </div>
          <div class="btn-more d-flex justify-content-end">
            <a href="#" class="d-flex pl-2" [ngClass]="'saiba-mais-' + category">
              Saiba Mais
              <span class="ml-2">
                <svg-icon src="./assets/arrow-right.svg" [svgStyle]="{ 'width.px':13 }"></svg-icon>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="courses" *ngIf="category === 'sou-clinica' ">
    <div class="container">
      <div class="row">
        <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Cursos e Minicursos</h2>

        <div class="col-lg-6">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ornare, elit vel tincidunt porttitor, turpis tortor lobortis turpis, at rutrum felis dolor quis leo. </p>
          <p>Nunc pharetra tincidunt elit non bibendum. Praesent iaculis augue a tortor pharetra. </p>
          <p>Sed congue nunc orci, vel suscipit mauris fringilla placerat. Etiam sed sapien auctor, convallis dolor ac, finibus justo. Quisque ac rutrum leo</p>
          <p>Phasellus quis purus quis mi semper rutrum non a dui. </p>
          <div class="btn-more d-flex justify-content-end">
            <a href="#" class="d-flex pl-2" [ngClass]="'saiba-mais-' + category">
              Saiba Mais
              <span class="ml-2">
                <svg-icon src="./assets/arrow-right.svg" [svgStyle]="{ 'width.px':13 }"></svg-icon>
              </span>
            </a>
          </div>
        </div>
        <div class="col-lg-6">
          <iframe  src="https://www.youtube.com/embed/Wp7PMUONolc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="products" *ngIf="category === 'sou-ed-fisica' ">
    <div class="container">
      <div class="row">
          <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Workshops e Cursos</h2>
          <div class="col-lg-4">
            <div class="card">
              <img class="card-img-top" src="https://via.placeholder.com/350x190" alt="Card image cap">
              <div class="card-body">
                <h5 class="card-title">Prod/Serv 1</h5>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
                <a href="#" class="btn" [ngClass]="'saiba-mais-' + category">Saiba mais</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card">
              <img class="card-img-top" src="https://via.placeholder.com/350x190" alt="Card image cap">
              <div class="card-body">
                <h5 class="card-title">Prod/Serv 2</h5>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
                <a href="#" class="btn" [ngClass]="'saiba-mais-' + category">Saiba mais</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card">
              <img class="card-img-top" src="https://via.placeholder.com/350x190" alt="Card image cap">
              <div class="card-body">
                <h5 class="card-title">Prod/Serv 3</h5>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque tristique tellus, nec sagittis magna lorem ut risus.</p>
                <a href="#" class="btn" [ngClass]="'saiba-mais-' + category">Saiba mais</a>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="blog">
    <div class="container">
      <div class="row">
          <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">Blog</h2>
          
          <div class="col-lg-4" *ngFor="let p of posts | reverse; let idx = index;">
            <div class="card">
              <img class="card-img-top" [src]="env.baseUpload + p.cover_article " [alt]="p.title_article">
              <div class="card-body">
                <h5 class="card-title">{{ p.title_article }}</h5>
                <div class="card-text" style="margin-bottom: 1rem;" [innerHtml]="p.resume_article"></div>
                <a [routerLink]="'./blog/' + p.slug_article" class="btn" [ngClass]="'saiba-mais-' + category">Leia mais</a>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>

  <div class="testimony">
    <div class="container">
      <div class="row">
        <h2 class="text-center d-block title" [ngClass]="'title-' + category" style="width: 100%;">O que falam sobre o Modelo Exerciência</h2>

        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">

            <div class="carousel-item active">
              <div class="row">
                <div class="col-lg-12 d-flex">

                  <div class="col-lg-4 text-center" *ngFor="let t of testimony | reverse; let idx = index; ">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div>

                  <!-- <div class="col-lg-4 text-center">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div>
                  <div class="col-lg-4 text-center">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div> -->

                </div>
              </div>

            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-lg-12 d-flex">
                  <div class="col-lg-4 text-center">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div>
                  <div class="col-lg-4 text-center">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div>
                  <div class="col-lg-4 text-center">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-lg-12 d-flex">
                  <div class="col-lg-4 text-center">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div>
                  <div class="col-lg-4 text-center">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div>
                  <div class="col-lg-4 text-center">
                    <div class="picture mb-3" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/250x250)'}"></div>
                    <div class="text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas, sapien ut pharetra dapibus, tortor neque</p>
                      <h5 [ngClass]="'nome-' + category">Nome da pessoa</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <svg-icon src="./assets/prev.svg" [svgStyle]="{ 'width.px':15 }"></svg-icon>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <svg-icon src="./assets/next.svg" [svgStyle]="{ 'width.px':15 }"></svg-icon>
          </a>
        </div>

      </div>
    </div>
  </div>

</div>

<app-footer [theme]="category" [menu]="menuNav"></app-footer>


