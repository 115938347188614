<div class="menu" [ngClass]="theme">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 menu-item">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" [routerLink]=" '/' ">
            <img src="./assets/logo.png" alt="ExerCiência" class="img-fluid">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" [ngClass]="{'sub-menu-mobile': mobile}" id="navbarSupportedContent">
            <ul class="navbar-nav" *ngIf="theme != ''; else noCategory ">
                <li *ngFor="let item of menu; let i = index" class="nav-item" [ngClass]="{'dropdown': item.submenu.length > 0}">
                  <a class="nav-link" [routerLink]="'/' + theme + '/' + item.path" *ngIf="item.submenu.length == 0">{{ item.name }}</a>
                  <a class="nav-link dropdown-toggle" [routerLink]="'/' + theme + '/' + item.path" *ngIf="item.submenu.length > 0" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ item.name }}</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="item.submenu.length > 0">
                    <a class="dropdown-item" *ngFor="let subitem of item.submenu" [routerLink]="'/' + theme + '/' + subitem.path" [fragment]="subitem.hash">{{ subitem.name }}</a>
                  </div>
                </li>
            </ul>
            <ng-template #noCategory>
              <ul class="navbar-nav">
                <li *ngFor="let item of menu; let i = index" class="nav-item" [ngClass]="{'dropdown': item.submenu.length > 0}">
                  <a class="nav-link" [routerLink]="'/' + item.path" *ngIf="item.submenu.length == 0">{{ item.name }}</a>
                  <a class="nav-link dropdown-toggle" [routerLink]="'/' + item.path" *ngIf="item.submenu.length > 0" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ item.name }}</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="item.submenu.length > 0">
                    <a class="dropdown-item" *ngFor="let subitem of item.submenu" [routerLink]="'/' + subitem.path" [fragment]="subitem.hash">{{ subitem.name }}</a>
                  </div>
                </li>
              </ul>
            </ng-template>
          </div>

        </nav>
      </div>
    </div>
  </div>
</div>
