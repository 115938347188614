import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private uri = 'api';
  constructor(private http: HttpClient) { }

  getBanner(id: number){
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(`${environment.baseUrl}/banners/${id}`, headers).pipe(map(data => data))
  }

  getInstitucional(){
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(`${environment.baseUrl}/allCategories`, headers).pipe(map(data => data))
  }

  getHomePosts(id: number){
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(`${environment.baseUrl}/blogHome/${id}`, headers).pipe(map(data => data))
  }

  getAllPosts(id: number){
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(`${environment.baseUrl}/blogPage/${id}`, headers).pipe(map(data => data))
  }

  getPost(slug: string){
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(`${environment.baseUrl}/blogSingle/${slug}`, headers).pipe(map(data => data))
  }

  getTestimony(id: number){
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(`${environment.baseUrl}/despositions/${id}`, headers).pipe(map(data => data))
  }

  getSearch(){
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(`${environment.baseUrl}/search`, headers).pipe(map(data => data))
  }

  getAbout(){
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(`${environment.baseUrl}/institucional`, headers).pipe(map(data => data))
  }
  
}
