<div class="header-inside" style="background-image: url('{{ pathImage }}');">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 content d-flex flex-column justify-content-end pb-4">
        <div class="breadcrumb-custom">
          <ul class="d-flex">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              {{ area }}
            </li>
          </ul>
        </div>
        <h2>{{ area }}</h2>
      </div>
    </div>
  </div>
</div>
