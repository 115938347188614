import { Component, OnInit } from '@angular/core';
import { menu } from '../menu/menu';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-institutional',
  templateUrl: './institutional.component.html',
  styleUrls: ['./institutional.component.scss']
})
export class InstitutionalComponent implements OnInit {
  category: string = '';
  menuNav: any = [];
  listCategory: any = [];
  env: any = environment;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.menuNav = menu.INSTITUCIONAL;
    this.loadInstitucional();
  }

  loadInstitucional(){
    this.apiService.getInstitucional().subscribe((data: any) => {
      let item = data.data.filter(i => i.name_category != 'Geral');
      this.listCategory = item;
    }, err => {
      console.log('ERR: ', err);
    })
  }

}
