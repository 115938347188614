<div id="carouselBanner" class="carousel slide" data-ride="carousel" data-interval="5000">
  <ol class="carousel-indicators" [ngClass]="theme" *ngIf="content.length > 0">
    <li *ngFor="let c of content; let first = first; let idx = index" data-target="#carouselBanner" [attr.data-slide-to]="idx" [ngClass]="{'active': first}"></li>
  </ol>

  <div class="carousel-inner">
    <div *ngFor="let c of content; let first = first; let idx = index" class="carousel-item" [ngClass]="{'active': first}">
      <picture>
        <source [srcset]="env.baseUpload + c.banner_mobile" media="(max-width: 450px)">
        <source [srcset]="env.baseUpload + c.banner_tablet" media="(max-width: 820px)">
        <img [srcset]="env.baseUpload + c.banner_desktop" alt="First slide">
      </picture>
    </div>
  </div>
</div>

